import React from 'react';
import { Layout, Col, Card } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import securityTags from '../assets/securitytags.png';
import businessTags from '../assets/businesstags.png';
import automationTags from '../assets/automationtags.png';
import nameTags from '../assets/nametags.png';
import versionTags from '../assets/versiontags.png';
import environmentTags from '../assets/environmenttags.png';
import categoriesTags from '../assets/categoriestags.png';
import midBgRevert from '../assets/bg-types.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Types() {
  return (
    <Content className={styles.basicInfo}>
      <Row>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          <span>What are tags?!</span>
        </h1>
        <div className={styles.frDiv}>
          <p className={styles.basicInfoTxtc}>
            Tags are metadata that can be assigned to resources in AWS. Each tag
            is a label that consists of a user-defined key and an optional
            value, providing information and context about those resources. For
            instance, tags can be used to identify who owns the resource, the
            environment in which the resource is used, and any other technical
            or business attributes that the business may require.
          </p>{' '}
        </div>
      </Row>

      <div className={styles.midBg}>
        <Row
          gutter={32}
          className={styles.respContainerTwo}
          style={{ margin: '50px auto' }}
        >
          <Col
            bordered={false}
            span={16}
            className={styles.responsiveCardCenterTwo}
          >
            <Card
              bordered={false}
              style={{ height: '525px', overflow: 'hidden', zIndex: '100' }}
              className={styles.reinsuranceCardOne}
            >
              <img
                src={categoriesTags}
                className={styles.categoriesImg}
                alt={'Categories Tags AWS'}
              />

              <h1>
                <span>The 4 majors categories</span>
                <br />
                of AWS tags.
              </h1>

              <p>
                - Technical
                <br />
                <br />
                - Automation
                <br />
                <br />
                - Business
                <br />
                <br />
                - Security
                <br />
                <br />
              </p>
            </Card>
          </Col>
        </Row>
      </div>

      <Row>
        <h1 style={{ width: '100%', textAlign: 'center' }}>
          Technical tags on AWS. <br />
          <span>
            Three major ones to <br />
            know.
          </span>
        </h1>
        <div style={{ width: '100%' }} className={styles.frDiv}>
          <p className={styles.basicInfoTxtc}>
            If implemented correctly, can help companies get an accurate idea of
            the spending and overall efficacy of their deployments.
          </p>
        </div>
      </Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            style={{ paddingTop: '24px', height: '380px' }}
            cover={
              <img
                className={styles.imgCard}
                src={nameTags}
                alt={'Name Tags AWS'}
              />
            }
            className={styles.reinsuranceCardSix}
          >
            <h2>Name</h2>
            <p>
              - Used to identify individual resources <br />
              - Usually employed effectively by most users in their resource
              management efforts <br />
            </p>
          </Card>
        </Col>

        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            style={{ paddingTop: '24px', height: '380px' }}
            cover={
              <img
                className={styles.imgCard}
                src={versionTags}
                alt={'Version Tags AWS'}
              />
            }
            className={styles.reinsuranceCardSix}
          >
            <h2>Version</h2>
            <p>
              - Used to distinguish between different versions of resources or
              applications <br />
              - Often not as crucial as the Name and Environment tags, Version
              tags come in handy for support purposes <br />
            </p>
          </Card>
        </Col>
      </Row>

      <Row className={styles.respContainerTwo} style={{ margin: '50px auto' }}>
        <Card
          bordered={false}
          style={{
            paddingTop: '24px',
            marginLeft: '16px',
            marginRight: '16px',
          }}
          cover={
            <img
              className={styles.imgCard}
              src={environmentTags}
              alt={'environment Tags AWS'}
            />
          }
          className={styles.reinsuranceCardSix}
        >
          <h2>Environment</h2>
          <p>
            - Used to help distinguish between development, test, production
            infrastructure <br />- Allows segmenting of resources based on the
            environment they belong to (development, production, test, etc),
            providing a simple means to evaluate each environment independently
            and simplify the process of pinpointing areas where resource usage
            is not optimized. <br />
            - Often not used adequately by the majority of AWS users. <br />
          </p>
        </Card>
      </Row>

      <Row
        gutter={24}
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '50px',
        }}
        className={styles.containerImgTypes}
      >
        <h1 className={styles.typesTitles}>
          <span>Automation Tags</span> on AWS.
        </h1>

        <Col span={12} style={{ zIndex: '100' }}>
          <p className={styles.typesInfoTxt}>
            Automation tags serve as the on/off switch for resources partaking
            in collective automation activities.
            <br />
            <br />
            <b>
              - Used to either opt-in or opt-out of automation activities <br />
              - Identify specific versions of resources that need to be updated,
              archived, or deleted <br />
              <br />
            </b>
            A typical example would be to selectively use start/stop scripts to
            turn off correctly tagged development infrastructure during
            non-working hours.
          </p>
        </Col>
        <Col span={12} style={{ paddingRight: '0px' }}>
          <img
            src={automationTags}
            style={{ transform: 'translate(100px, -20%)' }}
            className={styles.typesImg}
            alt={'Automation tags AWS'}
          />
        </Col>
      </Row>

      <Row
        gutter={24}
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '50px',
        }}
        className={styles.containerImgTypes}
      >
        <h1 className={styles.typesTitles2}>
          <span>Business tags</span> on AWS.
        </h1>

        <Col span={12} style={{ paddingLeft: '0px' }}>
          <img
            src={businessTags}
            style={{ float: 'none' }}
            className={styles.typesImg}
            alt={'Business tags AWS'}
          />
        </Col>

        <Col span={12} style={{ zIndex: '100' }}>
          <p className={styles.typesInfoTxt2}>
            <b>
              - Typically used to help track multiple cost centers (e.g. HR,
              sales, marketing, finance, etc)
              <br />
              - Assign a cost center associated with the resource
              <br />
              - Clearly identify who is responsible for a specific resource
              <br />
              - Describe the business purpose of the resource <br />
              <br />
            </b>
            Tags used for cost allocation fall under this category. AWS Cost
            Explorer and Cost and Usage Reports enable the ability to filter and
            break down costs by tag, providing organizations a better
            understanding of their AWS cloud costs per cost center.
          </p>
        </Col>
      </Row>

      <Row
        gutter={24}
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '50px',
        }}
        className={styles.containerImgTypes}
      >
        <h1 className={styles.typesTitles}>
          <span>Security tags</span> on AWS.
        </h1>

        <Col span={12} style={{ zIndex: '100' }}>
          <p className={styles.typesInfoTxt}>
            <b>
              - Used to identify and filter resources that require additional
              monitoring
              <br />
              - Permit or deny access to specific resources by using conditions
              within the Identity and Access Management (IAM) policies through
              regulating permissions based on specific tags and values
              <br />
              - Assist in meeting HIPAA or GDPR regulations. where the security
              of the deployment is of high importance <br />
            </b>
          </p>
        </Col>
        <Col span={12} style={{ paddingRight: '0px' }}>
          <img
            src={securityTags}
            className={styles.typesImg}
            alt={'Security Tags AWS'}
          />
        </Col>
      </Row>

      <Row
        gutter={32}
        className={styles.respContainerTwo}
        style={{ margin: '50px auto' }}
      >
        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            style={{ height: '480px' }}
            className={styles.reinsuranceCardOne}
          >
            <h2>
              <span>
                Additional,
                <br />
                optional tags.
              </span>
            </h2>
            <p>Set up these tags to gain additional insights.</p>
            Department = &lt;string&gt;
            <br />
            Creator = &lt;string&gt;
            <br />
            Technical Contact = &lt;string&gt;
            <br />
            Billing Contact = &lt;string&gt;
            <br />
            Terraform = &lt;yes&#124;no&gt;
            <br />
            Reserved = &lt;yes&#124;no&gt;
            <br />
            Encryption = &lt;yes&#124;no&gt;
            <br />
            Snapshot = &lt;yes&#124;no&gt;
            <br />
            Security Review = &lt;date&gt;
            <br />
          </Card>
        </Col>

        <Col
          bordered={false}
          span={16}
          className={styles.responsiveCardCenterTwo}
        >
          <Card
            bordered={false}
            style={{ height: '480px' }}
            className={styles.reinsuranceCardOne}
          >
            <h2>
              <span>Basic tags.</span>
            </h2>
            <p>
              At Tagbot we believe all AWS users should leverage tagging best
              practices to manage their resources more effectively.
            </p>
            Name = &lt;string&gt;
            <br />
            Environment = &lt;dev&#124;stg&#124;prod&gt;
            <br />
            Owner = &lt;string&gt;
            <br />
            Project = &lt;string&gt;
            <br />
            Workflow = &lt;string&gt;
            <br />
            Date of Creation = &lt;date&gt;
            <br />
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
